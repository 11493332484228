// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .goshop-modal {
        padding: 20px 15px;
    }

    .comments-list {
        gap: 20px;
    }

    .comment .author:before {
        display: none;
    }

    .comment .added-date {
        margin-left: 0;
        width: 100%;
    }

    .comment .purchase-confirmed {
        margin: 0;
        padding: 0;
        border: 0;
        margin-left: auto;
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    // OTHER
    #cookies-license {
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: none;
        padding: 10px 10px 10px 80px;

        &:before {
            left: 18px;
        }
    }

    .goshop-modal.goshop-modal-form {
        width: 90vw !important;
        max-width: none !important;
    }

    .goshop-modal:not(.goshop-modal-form),
    .modal-form-content {
        padding: 30px 10px !important;
    }

    .cart-success .goshop-modal, .availability-modal-wrapper .goshop-modal {
        padding: 0 !important;
    }

    .payment-channels-modal .goshop-modal {
        padding: 0 !important;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .header-contact li:has(.email) {
        display: none;
    }

    .main-banner b {
        bottom: 10px;
        left: 10px;
        right: 10px;
        width: auto;
        max-width: none;
        font-size: 16px;
    }

    .main-page-bottom-wrapper {
        flex-direction: column;
    }

    .main-page-contact {
        padding: 20px;
    }

    .producers-banner .wrapper a {
        width: 120px;
    }

    .navigation-wrapper {
        gap: 10px;
    }

    .detail-columns-wrapper {
        flex-direction: column;
        gap: var(--rowGap);
    }

    #detail-tabs nav button {
        min-width: auto;
    }

    .comments-stars-wrapper > div {
        font-size: 20px;
    }

    .comments-stars-wrapper i {
        font-size: 13px;
    }

    .comment .comment-response {
        margin-top: 10px;
    }

    .comment cite {
        padding: 10px 20px;
    }
}

@media (min-width: 601px) and (max-width: 1100px) {
    #detail-tabs .product:nth-child(4) {
        display: none;
    }
}
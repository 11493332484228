// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 30px;
        --rowGap: 30px;
    }

    .footer-item.contact {
        width: 70.7%;
    }

    .detail-columns-wrapper {
        width: 100%;
    }

    .detail-columns-wrapper {
        gap: 30px;
    }

    .detail-options,
    .detail-adding {
        width: 390px;
    }

    #detail-tabs nav button {
        min-width: 250px;
    }
}